/*
import Vue from 'vue'
// import Vuex from 'vuex'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import settings from '/ps_settings/config.json';
import langFileEs from '/source/lang_files/defunct.lang.es.json';
import langFileEn from '/source/lang_files/defunct.lang.en.json';

import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import SampleHeader from './samples/components/SampleHeader.vue'
import SampleFooter from './samples/components/SampleFooter.vue'

import routes from './routes'

import 'bootstrap/dist/css/bootstrap.min.css'

import { store } from './store'

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueCookies);

// Vue.$cookies.config(expireTimes[,path[, domain[, secure[, sameSite]]])  // default: expireTimes = 1d, path = '/', domain = '', secure = '', sameSite = ''
Vue.$cookies.config(settings.GENERIC_AUTH_EXPIRATION);

let langChoice = Vue.$cookies.get('langChoice');

if (langChoice === null) {
  langChoice = 'en';
  Vue.$cookies.set('langChoice', langChoice);
}

Vue.component( "Header", Header);
Vue.component( "Footer", Footer);
Vue.component( "SampleHeader", SampleHeader);
Vue.component( "SampleFooter", SampleFooter);

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes
});

store.state.langChoice = langChoice;

if (langChoice === 'en') {
    store.state.langFile = langFileEn;
} else if (langChoice === 'es') {
    store.state.langFile = langFileEs;
} else {
    store.state.langFile = langFileEn;
}

// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')*/

//stage test change
import Vue from 'vue';
import App from './App';
import VueCookies from 'vue-cookies';
// import settings from '/ps_settings/config.json';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { router } from './_helpers';
import { store } from './store';
import titleMixin from './mixins/titleMixin';
import Header from './components/Header.vue'
import HeaderPublic from './components/HeaderPublic.vue'
import Footer from './components/Footer.vue'
import GTM from './_helpers/gtm.js'
import _ from 'lodash'
//import { CCarousel } from '@coreui/vue'

Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.mixin(titleMixin);
//Vue.use(CCarousel);

Vue.$cookies.config(process.env.VUE_APP_AUTH_SESSION_EXPIRES_MILLISECONDS);

Vue.component( "GTM", GTM);
Vue.component( "Header", Header);
Vue.component( "HeaderPublic", HeaderPublic);
Vue.component( "Footer", Footer);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

